// App.js
import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Header from "./components/header/Header.js";
import Home from "./components/page/HomePage.js";
import LoginPage from "./components/page/LoginPage.js";
import JobContainer from "./components/JobHandling/JobContainer.js";
import OAuth2RedirectHandler from "./oauth2/OAuthRedirectHandler.js";
import NotFound from "./components/common/NotFound.js";
import ErrorPage from "./components/common/ErrorPage.js";
import LoadingIndicator from "./components/common/LoadingIndicator.js";
import PrivateRoute from "./routers/PrivateRoute.js";
import ManageUserPage from "./components/page/ManageUserPage.js";
import Dashboard from "./components/dashboard/Dashboard.js";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UnauthorizedPage from "./components/common/Unauthorized.js";
import ScrollToTop from "./components/design/ScrollToTop.js";
import { AuthProvider, useAuth } from './auth/AuthHandler.js';

const App = () => {
  const location = useLocation();
  const { authenticated, userRoles, loading, handleLogout, handleLogin, expiration } = useAuth();

  useEffect(() => {

    if (expiration) {
      const expirationTime = new Date(expiration).getTime();
      const currentTime = Date.now();

      if (expirationTime - currentTime <= 0) {
        // Token has expired, log out the user
        handleLogout();
        return;
      }

      const timeRemaining = expirationTime - currentTime;
      const refreshTimer = setTimeout(() => {
        window.location.reload(); // Refresh the page
      }, timeRemaining);

      return () => clearTimeout(refreshTimer); // Cleanup timer on component unmount
    }
  }, [handleLogout]);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="app">
      {!location.pathname.startsWith('/error') && !location.pathname.startsWith('/unauthorized') && authenticated && (
        <div className="app-top-box">
          <Header onLogout={handleLogout} />
        </div>
      )}
      <ScrollToTop />
      <div className="app-body">
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/unauthorized"
            element={
            <PrivateRoute
            element={<UnauthorizedPage userRoles={userRoles}/>}
            authenticated={authenticated}
            roles={['DATAENTRY', 'SALESPERSON', 'MANAGER', 'ADMIN','USER']}
            userRoles={userRoles}/>}/>
          <Route
            path="/home"
            element={
            <PrivateRoute
            element={<Home />}
            authenticated={authenticated}
            roles={['DATAENTRY', 'SALESPERSON', 'MANAGER', 'ADMIN']}
            userRoles={userRoles}/>}
          />
          <Route
            path="/create-job"
            element={
              <PrivateRoute
              element={ <JobContainer title="create-job" /> }
              roles={['SALESPERSON', 'MANAGER', 'ADMIN']}
              authenticated={authenticated}
              userRoles={userRoles}
              />
            }
          />
          <Route
            path="/update-job/:jobId"
            element={
              <PrivateRoute
              element={ <JobContainer title="update-job" /> }
              roles={['SALESPERSON', 'MANAGER', 'ADMIN']}
              authenticated={authenticated}
              userRoles={userRoles}
              />
            }
          />
          <Route
            path="/view-job/:jobId"
            element={
              <PrivateRoute
              element={ <JobContainer title="view-job" /> }
              roles={['DATAENTRY', 'SALESPERSON', 'MANAGER', 'ADMIN']}
              authenticated={authenticated}
              userRoles={userRoles}
              />
            }
          />
          <Route
            path="/assign-role"
            element={
              <PrivateRoute
              element={ <ManageUserPage title="view-job" /> }
              roles={['ADMIN']}
              authenticated={authenticated}
              userRoles={userRoles}
              />
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute
              element={ <Dashboard  /> }
              roles={['ADMIN']}
              authenticated={authenticated} 
              userRoles={userRoles}
              />
            } 
          />
          <Route
            path="/login"
            element={<LoginPage authenticated={authenticated} location={location} />}
          />
          <Route path="/oauth2/redirect" element={
            <OAuth2RedirectHandler onLogin={handleLogin} />} />
          <Route path="*" element={<Navigate to="/not-found" />} />
          <Route path="/not-found" element={<NotFound />} />
        </Routes>
      </div>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick draggable pauseOnHover />
    </div>
  );
};

// Wrap the App component with AuthProvider
const WrappedApp = () => (
  <AuthProvider>
      <App />
  </AuthProvider>
);

export default WrappedApp;
