import React, { useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { logoutUser } from "../../util/APIUtils.js";
import { ACCESS_TOKEN } from "../../constants";
import '../../styling/CommonPage.css';

const ErrorPage = () => {
    const query = new URLSearchParams(useLocation().search);
    const message = query.get('message') || "An unexpected error occurred.";
    const navigate = useNavigate();
    const token = localStorage.getItem(ACCESS_TOKEN);

    useEffect(() => {
        const timer = setTimeout(() => {
            if(token){
                logoutUser();
            }
            navigate('/login'); // Redirect to /home after 0.5 seconds
        }, 3000);

        // Directly navigate if the error message indicates a cancel
        if (message.includes("Login was canceled")) {
            clearTimeout(timer);
            navigate('/login');
        }

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, [navigate]);

    return (
        <div className="page-container">
            <h1 className="page-title">Error</h1>
            <div className="page-desc">{message}</div>
            <div className="redirect-message">You will be redirected shortly...</div>
            <Link to="/">
                <button className="go-home-btn" type="button">
                Go Back
                </button>
            </Link>
        </div>
    );
};

export default ErrorPage;
