import React from "react";
import { Link } from "react-router-dom";
import '../../styling/CommonPage.css';

const NotFound = () => {
  return (
    <div className="page-container">
      <h1 className="page-title">404</h1>
      <div className="page-desc">The page you're looking for was not found.</div>
      <div className="redirect-message">You will be redirected shortly...</div>
      <Link to="/">
        <button className="go-home-btn" type="button">
          Go Back
        </button>
      </Link>
    </div>
  );
};

export default NotFound;
