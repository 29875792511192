import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApiClient } from '../../service/API';
import FileUpload from '../FileHandling/FileUpload';
import FileList from '../FileHandling/FileList';
import FileViewer from '../FileHandling/FileViewer';
import NotificationModal from './NotificationModal';
import ConfirmationModal from './ConfirmationModal';
import FileUploadingModal from './FileUploadingModal';
import SearchableDropdown from './SearchableDropdown';
import { useAuth } from '../../auth/AuthHandler';

function JobForm({ title, jobInfo, jobId }) {
  const { userRoles } = useAuth();
  const navigate = useNavigate();
  const [remark, setRemark] = useState('');
  const [jobStatus, setJobStatus] = useState('NEW');
  const [jobStage, setJobStage] = useState('Stage 1');
  const [sysFreightRef, setSysFreightRef] = useState('');
  const [assignTo, setAssignTo] = useState('');
  const [files, setFiles] = useState([]);
  const [userList, setUserList] = useState([]);
  const [currentJobId, setCurrentJobId] = useState(null);
  const [jobFolderName, setJobFolderName] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errors, setErrors] = useState({ remark: '', files: '' });
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [redirectPath, setRedirectPath] = useState('');
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
  const [showCompleteConfirmation, setShowCompleteConfirmation] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const apiClient = useApiClient();

  useEffect(()=>{
      if (title !== 'create-job' && jobInfo) {
        setAssignTo(jobInfo.assignedTo);
        setRemark(jobInfo.remark);
        setJobStatus(jobInfo.status);
        setFiles(jobInfo.docs.map(doc => ({ name: doc })));
        setJobFolderName(jobInfo.folderName);
        setCurrentJobId(jobInfo.id);
        setSysFreightRef(jobInfo.sysFreightRef);
        setJobStage(jobInfo.jobStage);
        setSelectedFile({ name: jobInfo.docs[0] });
      }
      if(['MANAGER', 'ADMIN'].some(role => userRoles.includes(role))){
        fetchUserList();
      }
  },[jobId, jobInfo])

  const handleUpdateButton = useCallback((id) => {
    navigate(`/update-job/${id}`);
  }, [navigate]);

  const handleApiError = (error, defaultMessage) => {
    const message = error.response?.data?.businessExceptionDescription || defaultMessage;
    setShowUpdateConfirmation(false);
    setShowCompleteConfirmation(false);
    setShowNotification(true);
    setNotificationMessage(message);
  };

  const fetchUserList = async () => {
    try {
      const userListGet = await apiClient.get(`/users/list`);
      setUserList(userListGet.data);
    } catch (error) {
      // handleApiError(error, 'Error fetching user list. Please contact IT support.');
    }
  };

  const handleTextAreaChange = (event) => {
    const { value } = event.target;
    setRemark(value);
    setErrors(prevErrors => ({ ...prevErrors, remark: '' }));
  };

  const removeFile = (filename) => {
    setFiles(prevFiles => prevFiles.filter(file => file.name !== filename));
    setNotificationMessage('File deleted successfully');
    setShowNotification(true);
  };

  const validateFields = () => {
    const newErrors = {};
    let isValid = true;

    if(!jobStage.trim()){
        newErrors.JobStage = 'Job Stage is required';
        isValid = false;
    }

    if(!sysFreightRef.trim()){
        newErrors.SysFreight = 'Sysfreight Reference Number is required';
        isValid = false;
    }

    if (!remark.trim()) {
        newErrors.remark = 'Remark is required';
        isValid = false;
    }

    if (files.length === 0) {
        newErrors.files = 'At least one file is required';
        isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateFields()) {
      return;
    }

    const jsonPayload = {
      remark,
      status: jobStatus,
      docs: files.map(file => file.name),
      folderName: jobFolderName,
      jobHolderEmail: assignTo,
      jobStage: jobStage,
      sysFreightRef: sysFreightRef
    };

    try {
      if (title === 'create-job') {
        const response = await apiClient.post('/jobs/create-job', jsonPayload, {
          headers: { 'Content-Type': 'application/json' },
        });
        const { id, assignedTo } = response.data;
        setCurrentJobId(id);
        setAssignTo(assignedTo);
        if(title !== 'create-job'){
          fetchUserList();
        }
        setNotificationMessage('Job created successfully!');
        navigate(`/view-job/${id}`);
      } else if (title === 'update-job' && jobId) {
        setShowUpdateConfirmation(true);
      }
    } catch (error) {
      handleApiError(error, 'Error saving data. Please contact IT support.');
    }
    finally{
      setShowNotification(true);
    }
  };

  const handleCancel = () => {
    if (['create-job', 'view-job'].includes(title)) {
        navigate('/home');
    } else {
        navigate(`/view-job/${jobId}`);
    }
  };

  const handleModalClose = () => {
    setShowNotification(false);
    if (title !== 'update-job') {
      navigate(redirectPath);
    }
  };

  const fetchFile = async (filename) => {
    try {
      const response = await apiClient.get('/files/retrieve', {
        params: {
          folderName: jobFolderName,
          filename: filename
        },
        responseType: 'arraybuffer',
        headers: { 'Accept': 'application/json' }
      });

      if (response.status === 200) {
        const blob = new Blob([response.data]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(url);
      } else {
        throw new Error(`Server returned status: ${response.status}`);
      }
    } catch (err) {
      handleApiError(err, 'Unable to fetch the file.');
    }
  };

  const downloadAllFiles = async () => {
    for (const file of files) {
      await fetchFile(file.name);
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    if (!validateFields()) {
      return;
    }

    const jsonPayload = {
      remark,
      status: jobStatus,
      docs: files.map(file => file.name),
      folderName: jobFolderName,
      jobHolderEmail: assignTo,
      jobStage: jobStage,
      sysFreightRef: sysFreightRef
    };

    try {
      const response = await apiClient.put(`/jobs/update-job/${jobId}`, jsonPayload, {
        headers: { 'Content-Type': 'application/json' },
      });
      const { folderName, id } = response.data;
      setCurrentJobId(id);
      setJobFolderName(folderName);
      setNotificationMessage('Job updated successfully!');
      setShowUpdateConfirmation(false);
      setShowNotification(true);
    } catch (error) {
      handleApiError(error, 'Error updating data. Please contact IT support.');
    }
  };

  const handleComplete = async () => {
    setShowCompleteConfirmation(true);
  };

  const confirmComplete = async () => {
    try {
      await apiClient.patch(`/jobs/complete-job/${jobId}`);
      setNotificationMessage('Job completed successfully!');
      setRedirectPath('/home');
    } catch (error) {
      handleApiError(error, 'Error completing job. Please contact IT support.');
    } finally {
      setShowCompleteConfirmation(false);
      setShowNotification(true);
    }
  };

  const handleSelectionChange = (selectedEmail) => {
    setAssignTo(selectedEmail);
  };

  return (
    <div>
      <h1>{title === 'create-job' ? 'Create Job' : `Job ID: ${currentJobId}`}</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-3 row">
          <label htmlFor="currentJobId" className="col-sm-2 col-form-label">Job ID:</label>
          <div className="col-sm-10">
            <input
              type="text"
              readOnly
              className="form-control-plaintext"
              id="currentJobId"
              value={currentJobId || 'Not assigned yet'}
            />
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="assign-to" className="col-sm-2 col-form-label">Job Assign To:</label>
          <div className="col-sm-10">
          <input
            type="text"
            readOnly
            className="form-control-plaintext"
            id="assign-to"
            value={assignTo}
          />
          {(title === 'update-job' ) && ['MANAGER', 'ADMIN'].some(role => userRoles.includes(role)) &&
          <SearchableDropdown
            id="aassign-to"
            options={userList}
            selectedValue={assignTo}
            onSelectedChange={handleSelectionChange}
            placeholder="Select user"
          />}
          </div>
        </div>

        <div className="mb-3 row align-items-end">
          <label htmlFor="sysFreightRef" className="col-sm-2 col-form-label">SysFreight Reference Number:</label>
          <div className="col-sm-10">
            <input
              type="text"
              readOnly={(title === 'view-job') && true}
              className={(title === 'view-job') ? "form-control-plaintext" :`form-control ${errors.SysFreight ? 'is-invalid' : ''}`}
              id="sysFreightRef"
              value={sysFreightRef}
              onChange={(e) => setSysFreightRef(e.target.value)}
              maxLength={255}  // Limit input to 25 characters
            />
            {errors.SysFreight && <div className="invalid-feedback">{errors.SysFreight}</div>}
          </div>
        </div>

        {/* <div className="mb-3 row align-items-end">
          <label htmlFor="jobStage" className="col-sm-2 col-form-label">Job Stage:</label>
          <div className="col-sm-10">
            <input
              type="text"
              readOnly={(title === 'view-job') && true}
              className={(title === 'view-job') ? "form-control-plaintext" :`form-control ${errors.JobStage ? 'is-invalid' : ''}`}
              id="jobStage"
              value={jobStage}
              onChange={(e) => setJobStage(e.target.value)}
              maxLength={255}  // Limit input to 25 characters
            />
            {errors.JobStage && <div className="invalid-feedback">{errors.JobStage}</div>}
          </div>
        </div> */}

        <div className="mb-3 row">
          <label htmlFor="jobStage" className="col-sm-2 col-form-label">Job Stage:</label>
          <div className="col-sm-10">
            {(title === 'view-job') ?
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                id="jobStage"
                value={jobStage}
              />
            :
              <select
                id="jobStage"
                className="form-select"
                value={jobStage}
                onChange={(e) => setJobStage(e.target.value)}
              >
                <option value="Stage 1">Stage 1</option>
                <option value="Stage 2">Stage 2</option>
                <option value="Stage 3">Stage 3</option>
                <option value="Stage 4">Stage 4</option>
                <option value="Stage 5">Stage 5</option>
                <option value="Stage 6">Stage 6</option>
                <option value="Stage 7">Stage 7</option>
                <option value="Stage 8">Stage 8</option>
                <option value="Stage 9">Stage 9</option>
                <option value="Stage 10">Stage 10</option>
              </select>
            }
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="jobStatus" className="col-sm-2 col-form-label">Job Status:</label>
          <div className="col-sm-10">
          {(title === 'view-job') ?
            <input
              type="text"
              readOnly
              className="form-control-plaintext"
              id="jobStatus"
              value={jobStatus}
            />
          :
            <select
              id="jobStatus"
              className="form-select"
              value={jobStatus}
              onChange={(e) => setJobStatus(e.target.value)}
            >
              <option value="NEW">NEW</option>
              <option value="URGENT" disabled={!['MANAGER', 'ADMIN'].some(role => userRoles.includes(role))}>URGENT - REQUIRED MANAGER APPROVAL</option>
              <option value="ERROR">ERROR</option>
              <option value="COMPLETE">COMPLETE</option>
              <option value="DONE">DONE</option>
              <option value="CANCELLED">CANCELLED</option>
            </select>
          }
          </div>
        </div>

        <div className="mb-3 row">
          <label htmlFor="inputRemark" className="col-sm-2 col-form-label">Remark:</label>
          <div className="col-sm-10">
            <textarea
              name="remark"
              className={`form-control ${errors.remark ? 'is-invalid' : ''}`}
              id="inputRemark"
              rows="3"
              value={remark}
              onChange={handleTextAreaChange}
              disabled={title === 'view-job'}
            />
            {errors.remark && <div className="invalid-feedback">{errors.remark}</div>}
          </div>
        </div>

        <div className="mb-3 row">
        <label htmlFor="file" className="col-sm-2 col-form-label">Files:</label>
        <div className="col-sm-10" id="file">
            {title !== 'view-job' && (
              <div>
                <FileList files={files} removeFile={removeFile} folderName={jobFolderName} />
                <FileUpload setFileUploading={setFileUploading} files={files} setFiles={setFiles} jobFolderName={jobFolderName} setJobFolderName={setJobFolderName} />
              </div>
            )}
            {errors.files && <div className="text-danger mt-2">{errors.files}</div>}
            {title === 'view-job' && (
              <>
                <div className="row">
                  <div className='col-8'>
                    <select
                      id="fileList"
                      className="form-select"
                      value={selectedFile ? selectedFile.name : ''}
                      onChange={(e) => setSelectedFile(files.find(file => file.name === e.target.value))}
                      disabled={!files.length}
                    >
                      {files.map(file => (
                        <option key={file.name} value={file.name}>{file.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className='col-4'>
                    <button type="button" className="btn btn-secondary" onClick={downloadAllFiles}>
                      Download All Files
                    </button>
                  </div>
                </div>
                {selectedFile && <FileViewer fileId={selectedFile.name} folderName={jobFolderName} />}
              </>
            )}
          </div>
        </div>

        {(title === 'create-job') && (
          <div>
            <button type="submit" className="btn btn-primary">CREATE</button>
            <button type="button" className="btn btn-secondary ms-2" onClick={handleCancel}>Cancel</button>
          </div>
        )}

        {(title === 'update-job')&& (
          <div>
            <button type="submit" className="btn btn-primary">UPDATE</button>
            <button type="button" className="btn btn-secondary ms-2" onClick={handleCancel}>Cancel</button>
          </div>
        )}

        {title === 'view-job' && (
          <div>
            {(jobStatus !== 'COMPLETE' && jobStatus !== 'CANCELLED' && jobStatus !== 'DONE' && ['DATAENTRY'].some(role => userRoles.includes(role))) && (
              <button type="button" className="btn btn-primary" onClick={handleComplete}>
                Complete
              </button>
            )}
            {(['DATAENTRY'].some(role => !userRoles.includes(role))) && (
              <button type="button" className="btn btn-primary" onClick={()=>handleUpdateButton(currentJobId)}>
                Edit
              </button>
            )}
            <button type="button" className="btn btn-secondary ms-2" onClick={handleCancel}>
              Back to Home
            </button>
          </div>
        )}
      </form>

      {/* Notification Modal */}
      <NotificationModal
        show={showNotification}
        message={notificationMessage}
        onClose={handleModalClose}
        navigate={navigate}
        title={title}
      />
      <ConfirmationModal
        show={showUpdateConfirmation}
        onClose={() => setShowUpdateConfirmation(false)}
        onConfirm={handleUpdate}
        message="Are you sure you want to update this job?"
        title="Confirm Update"
      />
      <ConfirmationModal
        show={showCompleteConfirmation}
        onClose={() => setShowCompleteConfirmation(false)}
        onConfirm={confirmComplete}
        message="Are you sure you want to mark this job as complete?"
        title="Confirm Completion"
      />
      <FileUploadingModal
        isUploading={fileUploading}
      />
    </div>
  );
}

export default JobForm;
