import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/AuthHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { API_BASE_URL, ACCESS_TOKEN } from '../../constants';
import '../../styling/Header.css';
import { useApiClient } from '../../service/API';
import useWebSocket from '../../hooks/useWebSocketHook';

function Header({ onLogout }) {
  const navigate = useNavigate();
  const { authenticated, userEmail, userName, userRoles } = useAuth();
  const [alerts, setAlerts] = useState([]);
  const [msgAlerts, setMsgAlerts] = useState([]);
  const apiClient = useApiClient();
  // const [isConnected, setIsConnected] = useState(false);

  const fetchHistoryNotification = useCallback(async () => {
    try {
      const response = await apiClient.get(`jobs/notify/notification`);
      const historyMsg = response.data;
      setAlerts(historyMsg);
    } catch (error) {
      console.error(error.response?.data?.businessExceptionDescription || 'Error fetching job data. Please try again.');
    }
  }, [apiClient]);

  const fetchHistoryMsgNotification = useCallback(async () => {
    try {
      const response = await apiClient.get(`jobs/notify/msg-notification`);
      const historyMsg = response.data;
      setMsgAlerts(historyMsg);
    } catch (error) {
      console.error(error.response?.data?.businessExceptionDescription || 'Error fetching job data. Please try again.');
    }
  }, [apiClient]);

  useEffect(() => {
    fetchHistoryNotification();
    fetchHistoryMsgNotification();
  }, []);

  const handleNewMessage = (msg) => {
    toast.dismiss(); // Close existing toasts
    toast.success(`Alert received for JobId: ${msg.jobId}`); // Modify as necessary
    if (msg.type === 'MESSAGE') {
      setMsgAlerts((prevAlerts) => [...prevAlerts, msg]);
    } else {
      setAlerts((prevAlerts) => [...prevAlerts, msg]);
    }
  };

  const handleConnectionError = (error) => {
    console.error('WebSocket connection error:', error);
  };

  // Use the custom WebSocket hook
  const { isConnected } = useWebSocket(userEmail, null, handleNewMessage, null, handleConnectionError, null);

  // const connectWebSocket = () => {
  //   if (isConnected) return; // Prevent multiple connections
  //   const token = localStorage.getItem(ACCESS_TOKEN);
  //   const socket = new SockJS(`${API_BASE_URL}/chat?token=${token}`);

  //   socket.onerror = (error) => {
  //     console.error('Socket error:', error);
  //   };

  //   const stompClient = Stomp.over(socket);

  //   stompClient.connect({}, (frame) => {
  //     console.log('Connected to WebSocket:', frame);
  //     setIsConnected(true);

  //     // Subscribe to alerts
  //     stompClient.subscribe(`/topic/alert/${userEmail}`, (message) => {
  //       const msg = JSON.parse(message.body);
  //       handleNewMessage(`Alert received for JobId: ${msg.jobId}`);
  //       if (msg.type === "MESSAGE") {
  //         setMsgAlerts((prevAlerts) => [...prevAlerts, msg]);
  //       } else {
  //         setAlerts((prevAlerts) => [...prevAlerts, msg]);
  //       }
  //     });

  //     stompClient.onclose = () => {
  //       console.error('WebSocket connection closed.');
  //       setIsConnected(false);
  //       setTimeout(connectWebSocket, 5000); // Attempt to reconnect after 5 seconds
  //     };
  //   }, (error) => {
  //     console.error('Error connecting to WebSocket:', error);
  //     setIsConnected(false);
  //     setTimeout(connectWebSocket, 5000); // Attempt to reconnect after 5 seconds
  //   });
  // };

  // const handleNewMessage = (msg) => {
  //   toast.dismiss(); // Close existing toasts
  //   toast.success(msg); // Display the new message in a toast
  // };

  const handleClick = async (jobId) => {
    // Find the message to update
    const updatedMsgAlerts = msgAlerts.map((msg) => {
      if (msg.jobId === jobId) {
        // Perform API call to update the read status
        apiClient.put(`/jobs/chat/update-read-status/${msg.msgId}`).catch((error) => {
          console.error('Error updating read status:', error);
        });
        // Return the updated message
        return { ...msg, messageRead: true, read: true }; // Assuming 'read' is the property you want to set
      }
      return msg; // Return the original message if not updated
    });

    // Update state and navigate
    setMsgAlerts(updatedMsgAlerts);
    navigate(`/view-job/${jobId}`);
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light p-2">
        <div className="container-fluid d-flex justify-content-between align-items-center">
          {/* Logo */}
          <a className="navbar-brand" href="/home">
            <img src="/image/logo.jpg" alt="Logo" style={{ height: '40px' }} />
          </a>

          {/* Navbar Links */}
          {authenticated && (
            <ul className="navbar-nav d-flex flex-row mb-0">
              <li className="nav-item me-3">
                <a className="nav-link active" aria-current="page" href="/home">Home</a>
              </li>
              {userRoles.includes("ADMIN") && (
                <li className="nav-item me-3">
                  <a className="nav-link active" aria-current="page" href="/assign-role">Assign Role</a>
                </li>
              )}
              {['MANAGER', 'ADMIN'].some(role => userRoles.includes(role)) && (
                <li className="nav-item me-3">
                  <a className="nav-link active" aria-current="page" href="/dashboard">Performance Dashboard</a>
                </li>
              )}
              <li className="nav-item dropdown me-3">
                <a
                  className="nav-link"
                  role="button"
                  data-bs-toggle={alerts.length > 0 || msgAlerts.length > 0 ? "dropdown" : undefined}
                  aria-expanded={alerts.length > 0 || msgAlerts.length > 0}
                  href="#"
                >
                  <FontAwesomeIcon icon={faBell} />
                  {(alerts.filter(alert => !alert.read).length > 0 || msgAlerts.filter(alert => !alert.read).length > 0) && (
                    <span className="badge bg-danger">
                      {alerts.filter(alert => !alert.read).length + msgAlerts.filter(alert => !alert.read).length}
                    </span>
                  )}
                </a>
                {(alerts.length > 0 || msgAlerts.length > 0) && (
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li><h6 className="dropdown-header">Notify Job</h6></li>
                    <li><hr className="dropdown-divider" /></li>
                    {['URGENT', 'ERROR', 'COMPLETE'].map((type, typeIndex) => (
                      <React.Fragment key={`${type}-${typeIndex}`}>
                        {alerts.filter(alert => alert.type === type).map((alert, index) => (
                          <li key={`${alert.jobId}-${index}`}> {/* Unique key for alert */}
                            <a className={`dropdown-item ${alert.type} alert`} href={`/view-job/${alert.jobId}`}>
                              Job ID {alert.jobId}:
                              <br />{alert.message}
                            </a>
                          </li>
                        ))}
                      </React.Fragment>
                    ))}
                    <li><hr className="dropdown-divider" /></li>
                    <li><h6 className="dropdown-header">Notify Message</h6></li>
                    <li><hr className="dropdown-divider" /></li>
                    {msgAlerts.filter(alert => alert.type === "MESSAGE").map((alert, index) => (
                      !alert.read &&
                      <li key={`${alert.jobId}-${index}`}> {/* Unique key for msgAlerts */}
                        <a className={`dropdown-item MESSAGE alert`} onClick={()=>handleClick(alert.jobId)}>
                          Job ID {alert.jobId}:
                          <br />{alert.message}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
              <li className="nav-item dropdown me-3">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  User
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li><a href="#" className="dropdown-item disabled">{userName}</a></li>
                  <li><a href="#" className="dropdown-item disabled">{userEmail}</a></li>
                  <li><hr className="dropdown-divider" /></li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        const confirmLogout = window.confirm("Are you sure you want to log out?");
                        if (confirmLogout) {
                          onLogout();
                          navigate('/login');
                        }
                      }}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          )}
        </div>
      </nav>
    </div>
  );
}

export default Header;
