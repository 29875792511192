import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../auth/AuthHandler';
import '../../styling/CommonPage.css';

const UnauthorizedPage = ({userRoles}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const message = location.state?.message || "Unauthorized - You do not have permission to view this page";
    const { handleLogout } = useAuth();

    // State to control access
    const [canNavigate, setCanNavigate] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setCanNavigate(true); // Allow navigation after 0.5 seconds
            if(userRoles.length === 1 && userRoles.includes("USER")){
                handleLogout();
            }else{
                navigate('/home'); // Redirect to /home after 0.5 seconds
            }
        }, 3000);

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, [navigate]);

    // Prevent navigation if canNavigate is false
    const handleNavigation = (e) => {
        if (!canNavigate) {
            e.preventDefault(); // Block navigation
        }
    };

    // Use the event listener to block navigation
    useEffect(() => {
        window.addEventListener('beforeunload', handleNavigation);

        return () => {
            window.removeEventListener('beforeunload', handleNavigation);
        };
    }, [canNavigate]);

    return (
        <div className="page-container">
            <h1 className="page-title">403</h1>
            <div className="page-desc">{message}</div>
            <div className="redirect-message">You will be redirected shortly...</div>
            <button
                className="go-home-btn"
                onClick={() => navigate('/home')}
                disabled={!canNavigate}
            >
                Go to Home
            </button>
        </div>
    );
};

export default UnauthorizedPage;
