import React, { useState, useEffect } from 'react';
import SearchableDropdown from '../JobHandling/SearchableDropdown';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { useApiClient } from '../../service/API';
import '../../styling/Dashboard.css';

// Register chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [jobStats, setJobStats] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [type, setType] = useState('month');
  const [loading, setLoading] = useState(false);
  const apiClient = useApiClient();
  const [userList, setUserList] = useState([]);

  useEffect(()=>{
    fetchUserList();
  },[])

  const fetchUserList = async () => {
    try {
      const userListGet = await apiClient.get(`/users/list`);
      const all = {
        email: "",
        id: "",
        fullname: "All"
      };

      // Adding the "All" entry at the beginning of the list
      const userListWithAll = [all, ...userListGet.data];

      // Setting the list state with the new list
      setUserList(userListWithAll);
    } catch (error) {
      console.error(error, 'Error fetching user list. Please contact IT support.');
    }
  };


  // Handler to submit filter form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requestPayload = {
      userEmail,
      startDate,
      endDate,
      type,
    };

    try {
      const response = await apiClient.post('/dashboard/stats', requestPayload);
      setJobStats(response.data);
    } catch (error) {
      console.error("There was an error fetching the job stats", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    if (startDate && newEndDate < startDate) {
      // If the selected end date is before the start date, do not update
      alert("End date cannot be earlier than Start date.");
    } else {
      setEndDate(newEndDate);
    }
  };

  // Prepare chart data when jobStats is available
  const chartData = {
    labels: jobStats?.jobChartData?.labels || [],
    datasets: [
      {
        label: 'Completed Jobs',
        data: jobStats?.jobChartData?.datasets || [],
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
        tension: 0.1,
      },
    ],
  };

  const handleSelectionChange = (selectedEmail) => {
    setUserEmail(selectedEmail);
  };

  return (
    <div className="dashboard">
      <h1>Job Dashboard</h1>

      <form onSubmit={handleSubmit}>
        <div>
            <label>Email:</label>
            <SearchableDropdown
                options={userList}
                selectedValue={userEmail}
                onSelectedChange={handleSelectionChange}
                placeholder="Select user"
            />
        </div>
        <div>
          <label>Start Date:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div>
          <label>End Date:</label>
          <input
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            disabled={!startDate}
          />
        </div>
        <div>
          <label>Type:</label>
          <select value={type} onChange={(e) => setType(e.target.value)}>
            <option value="day">Day</option>
            <option value="month">Month</option>
            <option value="year">Year</option>
          </select>
        </div>

        <button type="submit" disabled={loading}>
          {loading ? 'Loading...' : 'Get Stats'}
        </button>
      </form>

      {jobStats && (
        <div>
          <h2>Statistics for {jobStats?.userEmail || "All Jobs"}</h2>
          <p>Completed Jobs: {jobStats.jobComplete}</p>
          <p>Ongoing Jobs: {jobStats.jobOngoing}</p>
          <p>Average Errors per Completed Job: {jobStats.avgErrorCountPerJob}</p>

          <h3>Completed Jobs Chart</h3>
          <Line data={chartData} />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
